<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-if="clusterId"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Bağımsız Bölüm Tanımları"
            icon="mdi-home-assistant"
            :add-route="
              can('add-house') ? { name: 'definitions.houses.create' } : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-house')"
            @click:edit="handleEditClick"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          >
            <template slot="buttons:append">
              <!-- <v-btn
                class="float-end ms-2"
                color="pink"
                outlined
                small
                @click="handleShareMeterActiveClick"
                :disabled="selectedItems.length === 0"
                v-if="can('edit-cluster')"
              >
                Pay Ölçer Aç
              </v-btn>

              <v-btn
                class="float-end ms-2"
                color="pink"
                outlined
                small
                @click="handleShareMeterPassiveClick"
                :disabled="selectedItems.length === 0"
                v-if="can('edit-cluster')"
              >
                Pay Ölçer Kapat
              </v-btn> -->

              <v-btn
                class="float-end ms-2"
                color="pink"
                outlined
                small
                v-if="$refs.importForm && can('edit-cluster')"
                @click="$refs.importForm.show()"
              >
                Toplu Güncelleme
              </v-btn>
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals :headers="headers" :totals="footTotals" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'definitions.houses.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.door_number="{ item, value }">
          <router-link
            :to="{
              name: 'definitions.houses.show',
              params: { id: item.id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.renter="{ item }">
          <router-link
            v-for="(item, i) in getRenterNames(item)"
            :key="i"
            :to="{
              name: 'definitions.residents.edit',
              params: { id: item.resident_id },
            }"
          >
            {{ item.resident.name }}

            <v-tooltip bottom v-if="item.is_staying">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="red" dark v-bind="attrs" v-on="on">
                  mdi-account-circle
                </v-icon>
              </template>
              Burada oturuyor.
            </v-tooltip>
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.owner="{ item }">
          <router-link
            v-for="(item, i) in getOwnerNames(item)"
            :key="i"
            :to="{
              name: 'definitions.residents.edit',
              params: { id: item.resident_id },
            }"
          >
            {{ item.resident.name }}

            <v-tooltip bottom v-if="item.is_staying">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="red" dark v-bind="attrs" v-on="on">
                  mdi-account-circle
                </v-icon>
              </template>
              Burada oturuyor.
            </v-tooltip>
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.total_assessment="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.total_payed="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable -->
        <template v-slot:item.balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>
        <template v-slot:item.renters_balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>
        <template v-slot:item.owners_balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>
        <template v-slot:item.has_periodical_assessments="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
        <template v-slot:item.has_multiple_owners="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
        <template v-slot:item.has_future_owner="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
        <template v-slot:item.future_owner_is_conditional="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
        <template v-slot:item.future_owner_name="{ item, value }">
          <router-link
            v-if="item.future_owner_id"
            :to="{
              name: 'definitions.residents.edit',
              params: { id: item.future_owner_id },
            }"
          >
            {{ item.future_owner_name }}
          </router-link>
        </template>
        <template v-slot:item.future_owner_delivery_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>
        <template v-slot:item.nodwelt="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
        <template v-slot:item.area_net="{ value }">
          <rs-table-cell-number :value="value" />
        </template>
        <template v-slot:item.area_gross="{ value }">
          <rs-table-cell-number :value="value" />
        </template>
        <template v-slot:item.area_garden="{ value }">
          <rs-table-cell-number :value="value" />
        </template>
        <template v-slot:item.area_land_share="{ value }">
          <rs-table-cell-number :value="value" />
        </template>
        <!-- eslint-enable -->
      </v-data-table>

      <ImportForm ref="importForm" @saved="loadList()" />
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";
import ImportForm from "./ImportForm";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters(["blocks", "cluster", "clusterId", "houseTypes"]),
  },
  components: {
    ImportForm,
  },
  data() {
    return {
      options: {
        sortBy: ["house_type_name"],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-house"),
        },
        {
          text: "B.B. No",
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.block"),
          value: "block_name",
          searchable: "multiselect",
          options: () => this.blocks,
          itemText: "name",
          width: "75px",
          align: "center",
        },
        {
          text: "B.B. Tipi",
          value: "house_type_name",
          searchable: "multiselect",
          options: () => this.houseTypes,
          itemText: "name",
          width: "75px",
          align: "center",
        },
        {
          text: "Kat",
          value: "floor",
          searchable: "text",
        },
        {
          text: "Tahakkuk Eden",
          value: "total_assessment",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ödenen Tutar",
          value: "total_payed",
          searchable: "number",
          align: "end",
        },
        {
          text: "BB Bakiye",
          value: "balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kiracı",
          value: "renter",
          searchable: "text",
          width: "200px",
        },
        {
          text: "Kiracı Bakiye",
          value: "renters_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ev Sahibi",
          value: "owner",
          searchable: "text",
          width: "200px",
        },
        {
          text: "Ev Sahibi Bakiye",
          value: "owners_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Periyodik Borçlandırma",
          value: "has_periodical_assessments",
          searchable: "yesNo",
          align: "center",
        },
        // {
        //   text: "Hissedarlı",
        //   value: "has_multiple_owners",
        //   searchable: "yesNo",
        //   align: "center",
        // },
        {
          text: "Durum",
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
        {
          text: "Oturan Yok",
          value: "nodwelt",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: "Net m²",
          value: "area_net",
          searchable: "number",
          align: "end",
          notPrice: true,
        },
        {
          text: "Brüt ²",
          value: "area_gross",
          searchable: "number",
          align: "end",
          notPrice: true,
        },
        {
          text: "Bahçe m²",
          value: "area_garden",
          searchable: "number",
          align: "end",
          notPrice: true,
        },
        {
          text: "Arsa Payı",
          value: "area_land_share",
          searchable: "number",
          align: "end",
          notPrice: true,
        },
        {
          text: "Sözleşmeli",
          value: "has_future_owner",
          searchable: "yesNo",
          align: "center",
          hide: () => !this.cluster || !this.cluster.has_future_owners,
        },
        {
          text: "Sözleşmeli Ev Sahibi",
          value: "future_owner_name",
          searchable: "text",
          hide: () => !this.cluster || !this.cluster.has_future_owners,
        },
        {
          text: "Teslim Tarihi",
          value: "future_owner_delivery_on",
          searchable: "date",
          hide: () => !this.cluster || !this.cluster.has_future_owners,
        },
        {
          text: "Şartlı Teslim",
          value: "future_owner_is_conditional",
          searchable: "yesNo",
          hide: () => !this.cluster || !this.cluster.has_future_owners,
        },
      ],
      titleBarAttrs: {
        exportUrl: () => `clusters/${this.clusterId}/houses`,
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      return params;
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.resetTable();

      const params = this.getParams();

      this.$api
        .query(`clusters/${this.clusterId}/houses`, { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getRenterNames(item) {
      const renterNames = [];

      for (const houseUser of item.house_users) {
        if (houseUser.house_user_type_id === 2) {
          renterNames.push(houseUser);
        }
      }

      return renterNames;
    },
    getOwnerNames(item) {
      const renterNames = [];

      for (const houseUser of item.house_users) {
        if (houseUser.house_user_type_id === 1) {
          renterNames.push(houseUser);
        }
      }

      return renterNames;
    },
    // handleShareMeterActiveClick() {
    //   if (this.selectedItems.length === 0) return;

    //   if (
    //     !window.confirm(
    //       `${this.selectedItems.length} bağımsız bölüm için pay ölçeri aktif etmek istediğinizden emin misiniz?`
    //     )
    //   ) {
    //     return;
    //   }

    //   this.updateShareMeter(true);
    // },
    // handleShareMeterPassiveClick() {
    //   if (this.selectedItems.length === 0) return;

    //   if (
    //     !window.confirm(
    //       `${this.selectedItems.length} bağımsız bölüm için pay ölçeri kapatmak istediğinizden emin misiniz?`
    //     )
    //   ) {
    //     return;
    //   }

    //   this.updateShareMeter(false);
    // },
    // updateShareMeter(value) {
    //   const formData = {
    //     ids: this.selectedItems.map((item) => item.id),
    //     has_share_meter_assessments: value,
    //   };

    //   this.isLoading = true;

    //   this.$api
    //     .put("houses", formData)
    //     .then(() => {
    //       this.$toast.success("Güncellendi.");
    //       setTimeout(() => this.loadList(), 100);
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     })
    //     .finally(() => (this.isLoading = false));
    // },
    handleEditClick() {
      if (this.selectedItems.length) {
        this.$router.push({
          name: "definitions.houses.edit",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
    clusterId() {
      this.loadList();
      setTimeout(() => {
        this.generateExportColumns(this.headers);
      }, 1000);
    },
  },
};
</script>
