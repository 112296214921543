<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">BB Bilgileri Toplu Güncelleme</template>
    <template slot="body">
      <v-form ref="formTemplate" @submit.prevent="handleDownloadFormSubmit">
        <v-row dense>
          <v-col v-for="(item, index) in availableColumns" :key="index" sm="4">
            <v-checkbox
              v-model="selectedColumns"
              :label="item.text"
              :value="item.value"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-save-close
          hide-cancel
          save-label="Şablonu İndir"
          v-if="selectedColumns.length > 0"
        />
        <p>
          <strong>ÖNEMLİ:</strong> Aktarımın başarıyla yapılabilmesi için şablon
          formatında değişiklik <strong>yapmamanız</strong> gerekir.
        </p>
      </v-form>

      <v-divider class="my-10"></v-divider>

      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="12">
            <rs-file
              label="Dosya"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
              @change="handleFileChange"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  name: "ImportForm",
  mixins: [hasForm],
  computed: {
    ...mapGetters(["clusterId"]),
    templateDownloadTarget() {
      return Vue.axios.defaults.baseURL + "templates/import/cluster";
    },
  },
  data() {
    return {
      file: null,
      availableColumns: [
        // {
        //   text: "Blok",
        //   value: "block_block",
        // },
        // {
        //   text: "Kat",
        //   value: "floor",
        // },
        // {
        //   text: "BB No",
        //   value: "door_number",
        // },
        {
          text: "BB Tipi",
          value: "house_type_name",
        },
        // {
        //   text: "BB Türü",
        //   value: "house_usage_type",
        // },
        // {
        //   text: "Ev Sahibi ATM Kodu",
        //   value: "owner_atm_code",
        // },
        // {
        //   text: "Kiracı ATM Kodu",
        //   value: "renter_atm_code",
        // },
        {
          text: "Brüt m²",
          value: "area_gross",
        },
        {
          text: "Net m²",
          value: "area_net",
        },
        {
          text: "Bahçe m²",
          value: "area_garden",
        },
        {
          text: "Pay Ölçer m²",
          value: "area_share_meter",
        },
        {
          text: "Arsa Payı",
          value: "area_land_share",
        },
        {
          text: "Periyodik Borçlandır",
          value: "has_periodical_assessments",
        },
        // {
        //   text: "Periyodik Alacaklandır",
        //   value: "has_periodical_crediting",
        // },
        // {
        //   text: "Pay Ölçer Borçlandırma",
        //   value: "has_share_meter_assessments",
        // },
        // {
        //   text: "Hissedarlı",
        //   value: "has_multiple_owners",
        // },
        {
          text: "Durum",
          value: "is_active",
        },
      ],
      selectedColumns: [],
    };
  },
  methods: {
    show() {
      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    hide() {
      this.formData.file = null;
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", this.file);
      data.append("cluster_id", this.$route.params.id);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post(`clusters/${this.clusterId}/import-houses`, data, config)
        .then((response) => {
          this.$toast.success("Kaydedildi");
          this.$emit("saved", response.data.data);
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDownloadFormSubmit() {
      if (!this.selectedColumns.length) {
        this.$toast.error("Güncellemek için en az bir sütun seçmelisiniz.");
        return false;
      }

      const params = {
        columns: this.selectedColumns,
      };

      this.isLoading = true;

      this.$api
        .post(`clusters/${this.clusterId}/import-houses-template`, params)
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href = response.data.data.url;
          anchor.target = "_blank";
          anchor.click();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleFileChange(file) {
      if (!file) {
        this.file = null;
        return;
      }

      const allowedMimes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
      ];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.file = null;
        this.$toast.error("Sadece XLSX veya ODS yükleyebilirsiniz.");
        return;
      }

      this.file = file;
    },
  },
};
</script>
